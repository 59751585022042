import React, { useEffect, useState } from "react";
import config from "../../config/config";
import { Link, useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Collapse from 'react-bootstrap/Collapse';
import '../../component/componentCss/sidebar.css'
import {
  getFormListForMenuAction,
  getMasterDataDetailsAction,
  getCategoryListForWebFormAction,
  getDepartmentMenuesAction,
  getDepartmentFormsTypeAction
} from "../../Action/admin.action";
import {
  BsGrid,
  BsPeople,
  BsPersonPlusFill,
  BsFileEarmarkCodeFill,
  BsFillPeopleFill,
  BsPersonBadgeFill,
  BsList,
  BsFileText,
  BsChevronDown,
  BsFillGearFill,
  BsCardList,
  BsHospital
} from "react-icons/bs";
import { GrSchedule } from "react-icons/gr";
import { TbReportMedical } from "react-icons/tb";

import { capitalizeFirstWord } from "../../config/conversion"
import Cookies from "js-cookie";
import { DropdownItem } from "react-bootstrap";
import { FaHistory } from "react-icons/fa";
import { MdNotificationsActive } from "react-icons/md";
import { decryptData } from './decrypt';
import propertise from "../../config/properties";

const Sidebar = () => {
  var pageURL = window.location.href;
  var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

  const [formList, setFormList] = useState([]);
  const [departmentFormList, setDepartmentFormList] = useState([]);
  const [formCategoryList, setFormCategoryList] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [departmentMenus, setDepartmentMenus] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [openUserManagement, setopenUserManagement] = useState(false);
  const [openUserManagementCms, setopenUserManagementCms] = useState(false);


  const [formsOpen, setFormsOpen] = useState(false);
  const [openDepartment, setOpenDepartment] = useState(false);
  const [departmentFormId, setDepartmentFormId] = useState(0);
  let loginData = !Cookies.get("loginSuccessNeurulaHealth")
    ? []
    : JSON.parse(Cookies.get("loginSuccessNeurulaHealth"));

  useEffect(() => {
    getFormListAPI();

    if (lastURLSegment == 'isPatiendSearch') {
      getFormTypesListAPI()
    }

  }, []);

  const handleClick = () => {
    setOpenPermission(!openPermission);
  };

  const handleClickUserManagement = () => {
    setopenUserManagement(!openUserManagement);
  };

  const handleClickUserManagementCms = () => {
    setopenUserManagementCms(!openUserManagementCms)
  }

  let getFormListAPI = async () => {
    let res = await getFormListForMenuAction();
    if (res.success) {
      setFormList(res.data);
    }
  };

  let getDepartmentMenuesAPI = async () => {
    let res = await getDepartmentMenuesAction();
    if (res.success) {
      setDepartmentMenus(decryptData(res.data));
    }
  };

  let getFormCategoryAPI = async () => {
    let res = await getCategoryListForWebFormAction();
    if (res.success) {
      setFormCategoryList(decryptData(res.data));
    }
  };

  let getMasterData = async () => {
    let res = await getMasterDataDetailsAction();
    if (res.success) {
      setMasterData(res.data);
    } else {
      setMasterData([]);
    }
  };

  let getFormTypesListAPI = async () => {
    let MRNNumber = localStorage.getItem('mrnNumber');
    let res = await getDepartmentFormsTypeAction({ 'MRNNumber': MRNNumber });
    if (res.success) {
      let data = decryptData(res.data)
      setDepartmentFormList(data);
    }
  };

  return (
    <>
      <nav id="sidebar">
        <div className="sideinr pt-4">
          <div className="sidebar_logo d-none">
            <span>
              <img
                src="assets/images/mainlogo.png"
                className="mainlogo"
                alt="mainlogo"
              />
            </span>
          </div>
          <ul className="me-auto">
            {loginData?.role == 1 || loginData?.role == 2 ? (
              <>
                <li className={lastURLSegment == "dashboard" ? "active" : ""}>
                  <Link to={`${config.baseUrl}dashboard`} className="nav-link ">
                    <span>
                      <BsGrid />
                    </span>
                    Dashboard
                  </Link>
                </li>

                <li>
                  <Link to="#" className="nav-link">
                    <div className="d-flex flex-row align-items-center">
                      <span>
                        <BsPersonBadgeFill />
                      </span>
                      <p
                        className="mb-0 pe-5"
                        onClick={handleClickUserManagement}
                        aria-controls="example-collapse-text"
                        aria-expanded={openUserManagement}
                      >
                        User Management
                      </p>
                      <BsChevronDown />
                    </div>
                  </Link>
                </li>

                <Collapse in={openUserManagement}>
                  <div id="example-collapse-text-1">
                    <li className={lastURLSegment === "staff-management" ? "active" : ""}>
                      <Link to={`${config.baseUrl}staff-management`} className="nav-link">
                        <span>
                          <BsList />
                        </span>Users List
                      </Link>
                    </li>
                    <li className={lastURLSegment === "duty-schedule" ? "active" : ""}>
                      <Link to={`${config.baseUrl}duty-schedule`} className="nav-link">
                        <span>
                          <BsList />
                        </span>Duty Schedule
                      </Link>
                    </li>
                    <li className={lastURLSegment === "UserPermissions" ? "active" : ""}>
                      <Link to={`${config.baseUrl}UserPermissions`} className="nav-link">
                        <span>
                          <BsList />
                        </span>User Permission
                      </Link>
                    </li>
                    <li className={lastURLSegment === "rolesPermission" ? "active" : ""}>
                      <Link to={`${config.baseUrl}rolesPermission`} className="nav-link">
                        <span>
                          <BsList />
                        </span>Roles Permission
                      </Link>
                    </li>
                    <li className={
                      lastURLSegment == "user-group" ||
                        lastURLSegment == "add-user-group"
                        ? "active"
                        : ""
                    }>
                      <Link to={`${config.baseUrl}user-group`} className="nav-link">
                        <span>
                          <BsList />
                        </span>Roles
                      </Link>
                    </li>
                  </div>

                </Collapse>
                <li
                  className={
                    lastURLSegment == "department-list" ? "active" : ""
                  }
                >
                  <Link
                    to={`${config.baseUrl}department-list`}
                    className="nav-link "
                  >
                    <span>
                      <BsPersonBadgeFill />
                    </span>
                    Department
                  </Link>
                </li>

                <li
                  className={
                    lastURLSegment == "form-category-list" ? "active" : ""
                  }
                >
                  <Link
                    to={`${config.baseUrl}form-category-list`}
                    className="nav-link "
                  >
                    <span>
                      <BsFileText />
                    </span>
                    Form Category
                  </Link>
                </li>

                <li
                  className={
                    lastURLSegment == "department-forms-list" ? "active" : ""
                  }
                >
                  <Link
                    to={`${config.baseUrl}department-forms-list`}
                    className="nav-link "
                  >
                    <span>
                      <BsPersonBadgeFill />
                    </span>
                    Folders
                  </Link>
                </li>

                <li className={lastURLSegment == "master-data" ? "active" : ""}>
                  <Link to={`${config.baseUrl}master-data`} className="nav-link ">

                    <div className="d-flex flex-row align-items-center">
                      <span>
                        <BsPersonBadgeFill />
                      </span>
                      <p className="mb-0 pe-5"
                        onClick={() => {
                          setOpen(!open);
                          getMasterData();
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}>
                        Master Data
                      </p>

                      <BsChevronDown />
                    </div>
                  </Link>
                </li>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    {masterData.map((data) => (
                      <li
                        className={
                          lastURLSegment == "viewMasterDataList"
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href={`${config.baseUrl}viewMasterDataList/${data.id}/${data.dbTable}`}
                          className="nav-link  mastersidebar"
                        >
                          <span>
                            <BsList />
                          </span>

                          {capitalizeFirstWord(data.dbTable)}
                        </a>
                      </li>
                    ))}
                  </div>
                </Collapse>

                <li className={lastURLSegment == "webforms" ? "active" : ""}>
                  <Link to={`${config.baseUrl}webforms`} className="nav-link ">
                    <span>
                      <BsFileEarmarkCodeFill />
                    </span>
                    Web Form
                  </Link>
                </li>

                <li>
                  <Link to="#" className="nav-link">
                    <div className="d-flex flex-row align-items-center">
                      <span>
                        <BsPersonBadgeFill />
                      </span>
                      <p
                        className="mb-0 pe-5"
                        onClick={handleClickUserManagementCms}
                        aria-controls="example-collapse-text"
                        aria-expanded={openUserManagementCms}
                      >
                        CMS
                      </p>
                      <BsChevronDown />
                    </div>
                  </Link>
                </li>

                <Collapse in={openUserManagementCms}>
                  <div id="example-collapse-text-1">
                    <li className={lastURLSegment === "reportcontent" ? "active" : ""}>
                      <Link to={`${config.baseUrl}reportcontent`} className="nav-link">
                        <span>
                          <BsList />
                        </span>Report content
                      </Link>
                    </li>

                  </div>

                </Collapse>

                <li className={lastURLSegment == "logHistory" ? "active" : ""}>
                  <Link to={`${config.baseUrl}logHistory`} className="nav-link ">
                    <span>
                      <FaHistory />
                    </span>
                    Log History
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className={lastURLSegment == "staffDashboard" ? "active" : ""}>
                  <Link to={`${config.baseUrl}staffDashboard`} className="nav-link ">
                    <span>
                      <BsGrid />
                    </span>
                    Dashboard
                  </Link>
                </li>
                <li className={lastURLSegment == "admission" ? "active" : ""}>
                  <Link to={`${config.baseUrl}form-submission/${propertise.patientAdmit}/0`} className="nav-link ">
                    <span>
                      <BsHospital />
                    </span>
                    Admission
                  </Link>
                </li>

                <li className={lastURLSegment == "profile" ? "active" : ""}>
                  <Link to={`${config.baseUrl}profile`} className="nav-link ">
                    <span>
                      <BsFillGearFill />
                    </span>
                    Setting
                  </Link>
                </li>

                <li className={lastURLSegment == "calendar" ? "active" : ""}>
                  <Link to={`${config.baseUrl}calendar`} className="nav-link ">
                    <span>
                      <GrSchedule />
                    </span>
                    Appointments
                  </Link>
                </li>
              </>


            )}
            <li className={lastURLSegment == "notifications" ? "active" : ""}>
              <Link to={`${config.baseUrl}notifications`} className="nav-link ">
                <span>
                  <MdNotificationsActive size={20} />
                </span>
                Notification
              </Link>
            </li>
            <li className={lastURLSegment == "reportslist" ? "active" : ""}>
              <Link to={`${config.baseUrl}reportslist`} className="nav-link ">
                <span>
                  <TbReportMedical size={20} />
                </span>
                Reports
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;




