import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import "../componentCss/sidebar.css";
import {
  getFormDataListAction,
  getFormDetailsAction,
  getPatientDetailsAction,
  getAllReportContentAction
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { decryptData } from './decrypt';
import config from "../../config/config";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const patientMrnNumber = localStorage.getItem('patientMrnNumber');

const today = new Date();
const options = { day: '2-digit', month: 'long', year: 'numeric' };
const todayDate = today.toLocaleDateString('en-GB', options);

const EditFormData = () => {
  let { formId, recordId, name } = useParams();
  const [formList, setFormData] = useState({});
  const [formDetails, setFormDetails] = useState([]);
  const [reason, setReason] = useState('');
  const [reasonStatus, setReasonStatus] = useState('');
  const [patientDetails, setPatientDetails] = useState([]);
  const allDataRef = useRef();
  const allDataRefRecords = useRef();
  const [reportData, setReportData] = useState('');

  const [staffFormEditPermission, setStaffFormEditPermission] = useState();

  useEffect(() => {
    getFormDetailsAPI();
    getReportContentList();

    if (patientMrnNumber) {
      getPatientDetailsAPI();
      getFormDataListAPI();
    }
  }, []);

  const getReportContentList = async () => {
    let res = await getAllReportContentAction();
    if (res.success) {
      let decryptedData = decryptData(res.data);
      setReportData(decryptedData)
    }
  };

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  const printAllDataRecords = useReactToPrint({
    content: () => allDataRefRecords.current,
  });


  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      setStaffFormEditPermission(decryptData(res.staffFormEditPermission));
    }
  };

  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({ id: formId, recordId: recordId, mrnNumber: patientMrnNumber });
    if (res.success) {
      let data = decryptData(res.data?.records);
      localStorage.setItem('mrnNumber', data[0].mrnNumber);
      setFormData(data[0]);
      setReason(decryptData(res.data.reasonOfRecord));
      setReasonStatus(decryptData(res.data.reasonStatus));
    }
  };

  let getPatientDetailsAPI = async () => {
    let res = await getPatientDetailsAction({ mrnNumber: patientMrnNumber });
    if (res.success) {
      let data = decryptData(res.data);
      const extractedValues = data.filter(item => item.formDetailsId === 16 || item.formDetailsId === 18).map(item => item.fieldValue);
      setPatientDetails(extractedValues);
    }
  };


  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        {/* <Sidebar /> */}
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={3} md={3} sm={3}> <div className="form-preview-container">
                <AssignedFormSidebar />
              </div></Col>
              <Col lg={9} md={9} sm={9}> <div className="form-preview-container" id="example-collapse-text">
                <h2>{name} Record Details</h2>
                <Link to={formId == 3 || formId == 4 || formId == 5 ? `${config.baseUrl}staffDashboard` : `${config.baseUrl}viewTreatment/${formId}/${recordId}/${name}`} >
                  <span style={{ float: 'right', marginTop: '-15px' }}>Back</span>
                </Link>
                <ul className="list-group">
                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>Patient Name:</strong></div>
                    {patientDetails[0] ? patientDetails[0] : ''} {patientDetails[1] ? patientDetails[1] : ''}
                  </li>

                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>MRN Number:</strong></div>
                    {formList.mrnNumber}
                  </li>

                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>Emirates ID Number:</strong></div>
                    {formList.emrNumber}
                  </li>

                  {formDetails.map((fields, index) => (
                    <li key={index} className="list-group-item d-flex flex-row ">
                      <div className="w-50"><strong>{fields.fieldName}:</strong></div>
                      {fields.fieldtype == 'file' ?
                        <>
                          <img src={`${formList[fields.id]}`} height="150px" width="200px" />
                        </>
                        :
                        fields.fieldtype == 'multipleValue' ?
                          <>
                            <ul>
                              {formList[fields.id] &&
                                JSON.parse(formList[fields.id]).map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))
                              }
                            </ul>
                          </>
                          :
                          fields.fieldtype == 'masterdata' ?
                            <>
                              <div>
                                <table className="table table-responsive" style={{ width: '100%' }}>
                                  <thead>
                                    <tr>
                                      {formList[fields?.id] && JSON.parse(formList[fields?.id]).length > 0 ? (
                                        Object.keys(JSON.parse(formList[fields?.id])[0]).map((key, index) => (
                                          <th key={index} style={{ textAlign: 'left' }}>{key.replace(/([A-Z])/g, ' $1')}</th>
                                        ))
                                      ) : (
                                        <th>No Data Available</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {formList[fields?.id] ? (
                                      JSON.parse(formList[fields?.id]).map((item, index) => (
                                        <tr key={index}>
                                          {Object.entries(item).map(([key, value]) => (
                                            <td key={key}>
                                              {typeof value === 'object' && value !== null ? (
                                                Object.entries(value).map(([subKey, subValue]) => (
                                                  <div key={subKey}><strong>{subKey}:</strong> {subValue}</div>
                                                ))
                                              ) : (
                                                value !== null && value !== undefined ? value.toString() : "-"
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="100%" style={{ textAlign: 'center' }}>No Data Available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>


                            </> :
                            formList[fields.id]
                      }

                    </li>
                  ))}

                  {reason &&
                    <li className="list-group-item">
                      {reasonStatus == 2 ?
                        <strong>Invalidate:</strong>
                        :
                        reasonStatus == 3 ?
                          <strong>Addendum:</strong>
                          :
                          reasonStatus == 6 ?
                            <strong>Review/Acknowledgement:</strong>
                            : 'Reason'
                      }
                      {reason}
                    </li>
                  }
                  {staffFormEditPermission?.isEdit == 1 &&
                    <div className="row">
                      <div className="col-md-3">
                        <br />
                        <Link to={`${config.baseUrl}editForm-submission/${formId}/${recordId}/${name}`}>
                          <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Modify</button>
                        </Link>
                        &nbsp;&nbsp;
                        <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print" onClick={printAllDataRecords}>Print</button>

                      </div>
                    </div>
                  }
                </ul>
              </div></Col>
              {formId == 3 &&
                <Col className='' style={{ marginTop: "30px" }}>
                  <div className="form-preview-container">
                    <div className="printStickerBtn">
                      <ReactToPrint
                        trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print '>Print</button>}
                        content={printAllData}
                      />
                    </div>
                    <Row>
                      <Col md={12}>
                        <div className="p-3 mb-4" style={{ border: '1px solid #ccc', borderRadius: '5px' }} ref={allDataRef}>
                          <h5 className="mb-3"><b>Sticker</b></h5>
                          <Row>
                            <Col md={6} className="mb-3">
                              <strong>MRN</strong>: {formList.mrnNumber}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[4]?.fieldName}</strong>: {formList[16] ? formList[16] : 'NA'} &nbsp; <strong>{formDetails[6]?.fieldName}</strong>: {formList[18] ? formList[18] : 'NA'}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[9]?.fieldName}</strong>: {formList[21] ? formList[21] : 'NA'}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[8]?.fieldName}</strong>: {formList[20] ? formList[20] : 'NA'}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>{formDetails[10]?.fieldName}</strong>: {formList[22] ? formList[22] : 'NA'}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>Physician</strong>: {formList[2194] ? formList[2194] : formList[1570] ? formList[1570] : 'NA'}
                            </Col>
                            <Col md={6} className="mb-3">
                              <strong>EMR</strong>: {formList.emrNumber}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              }
            </Row>
          </Container>


          <div ref={allDataRefRecords} className="printable11-content">

            {/* Header for print */}
            {/* <div className="print-header">
              <h2 className="text-center">Patient Report</h2>
              <hr />
            </div> */}
            <div
              className="custom-header d-flex justify-content-between"
              style={{ borderBottom: "1px solid #3EC4F1", padding: "10px" }}
            >
              <img
                src={reportData?.logo || "assets/images/logo2.png"}
                className="img-fluid"
                style={{ width: 200, height: 150, marginTop: "-20px" }}
                alt=""
              />
              <div>
                <p className="mb-0 fs12"><b> Address: </b> {reportData?.address || ''}</p>
                <p className="mb-0 fs12"><b> Phone Number:</b> {reportData?.contactNumber || ''}</p>
                <p className="mb-0 fs12"><b>Patient Name:</b> {`${patientDetails[0]} ${patientDetails[1]} `}</p>
                <p className="mb-0 fs12"><b>MRN Number:</b> {formList.mrnNumber}</p>
                <p className="mb-0 fs12"><b> Date: </b> {todayDate}</p>
              </div>
            </div>
            <br />

            <h2 style={{ marginLeft: '20px' }}>{name} Record Details</h2>

            <ul className="list-group">
              <li className="list-group-item d-flex flex-row">
                <div className="w-50"><strong>Emirates ID Number:</strong></div>
                {formList.emrNumber}
              </li>

              {formDetails.map((fields, index) => (
                <li key={index} className="list-group-item d-flex flex-row">
                  <div className="w-50"><strong>{fields.fieldName}:</strong></div>
                  {fields.fieldtype === 'file' ?
                    <img src={`${formList[fields.id]}`} height="150px" width="200px" alt="file preview" />
                    : fields.fieldtype === 'multipleValue' ?
                      <ul>
                        {formList[fields.id] &&
                          JSON.parse(formList[fields.id]).map((item, index) => (
                            <li key={index}>{item}</li>
                          ))
                        }
                      </ul>
                      : fields.fieldtype === 'masterdata' ?
                        <div style={{ overflowX: 'auto', maxHeight: '400px', border: '1px solid #ddd' }}>
                          <table className="table table-responsive" style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                {formList[fields?.id] && JSON.parse(formList[fields?.id]).length > 0 ? (
                                  Object.keys(JSON.parse(formList[fields?.id])[0]).map((key, index) => (
                                    <th key={index} style={{ textAlign: 'left' }}>{key.replace(/([A-Z])/g, ' $1')}</th>
                                  ))
                                ) : (
                                  <th>No Data Available</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {formList[fields?.id] ? (
                                JSON.parse(formList[fields?.id]).map((item, index) => (
                                  <tr key={index}>
                                    {Object.entries(item).map(([key, value]) => (
                                      <td key={key}>
                                        {typeof value === 'object' && value !== null ? (
                                          Object.entries(value).map(([subKey, subValue]) => (
                                            <div key={subKey}><strong>{subKey}:</strong> {subValue}</div>
                                          ))
                                        ) : (
                                          value !== null && value !== undefined ? value.toString() : "-"
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="100%" style={{ textAlign: 'center' }}>No Data Available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        : formList[fields.id]
                  }
                </li>
              ))}

              {reason &&
                <li className="list-group-item">
                  {reasonStatus == 2 ? <strong>Invalidate:</strong> :
                    reasonStatus == 3 ? <strong>Addendum:</strong> :
                      reasonStatus == 6 ? <strong>Review/Acknowledgement:</strong> : 'Reason'
                  }
                  {reason}
                </li>
              }
            </ul>

            {/* Footer for print */}
            <div className="print-footer">
              <hr />
              <p style={{ marginLeft: '20px' }}>{reportData?.footerDescription || ''}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFormData;
