import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "../componentCss/webform.css";
import "../componentCss/sidebar.css";
import config from "../../config/config";
import Collapse from 'react-bootstrap/Collapse';
import { capitalizeFirstWord } from "../../config/conversion";
import { BsChevronDown, BsList, BsGrid, BsPrescription } from "react-icons/bs";
import { getDepartmentFormsTypeAction } from "../../Action/admin.action";
import { decryptData } from './decrypt';
import toast from "react-hot-toast";

const AssignedFormSidebar = () => {
  let { recordId } = useParams();
  const [openForm, setOpenForm] = useState(null);
  const [departmentFormList, setDepartmentFormList] = useState([]);
  const [allAssignedFormsList, setAllAssignedFormsList] = useState([]);
  const [assignedFormsList, setAssignedFormsList] = useState([]);
  const [assignedFormsTypes, setAssignedFormsTypes] = useState([]);

  useEffect(() => {
    const getFormTypesListAPI = async () => {
      let MRNNumber = localStorage.getItem('mrnNumber');
      let res = await getDepartmentFormsTypeAction({ 'MRNNumber': MRNNumber });
      if (res.success) {
        let data = decryptData(res.data);
        let assignedForms = decryptData(res.assignedForms);
        let getAssignedFormsTypes = decryptData(res.getAssignedFormsTypes);

        let accessFormFilter = assignedForms.filter(item2 =>
          !data.some(item1 => item1.formId === item2.formId)
        );

        let accessFormTypesFilter = getAssignedFormsTypes.filter(item2 =>
          !data.some(item1 => item1.formId === item2.formId)
        );

        const uniqueForms = accessFormFilter.filter((item, index, self) =>
          index === self.findIndex((t) => t.formId === item.formId)
        );

        // accessFormTypesFilter = accessFormTypesFilter.filter(item => item.staffId !== null);
        accessFormTypesFilter = [...new Map(accessFormTypesFilter.map(item => [item.formTypeId, item])).values()];

        setAssignedFormsTypes(accessFormTypesFilter);
        setAllAssignedFormsList(uniqueForms);

        const orderedData = data.sort((a, b) => {
          const order = [3, 5, 4];  // Desired order of formId
          return order.indexOf(a.formId) - order.indexOf(b.formId);
        });
        setDepartmentFormList(orderedData);
      }
    };

    getFormTypesListAPI();
  }, []);

  const openFormList = useCallback((formTypeId) => {
    let formsList = allAssignedFormsList.filter(item => item.formTypeId === formTypeId);
    if (formsList.length == 0) {
      toast.error('No form assigned in this department.')
    }
    setAssignedFormsList(formsList);
  }, [allAssignedFormsList]);

  const handleToggle = useCallback((formTypeId) => {
    setOpenForm(openForm === formTypeId ? null : formTypeId);
  }, [openForm]);

  return (
    <div id="example-collapse-text">

      <a href={`${config.baseUrl}staffdashboard`} className="nav-link ">
        <span>
          <BsGrid /> &nbsp;
        </span>
        Dashboard
      </a>

      <a href={`${config.baseUrl}prescriptionList`} className="nav-link mt-2">
        <span>
          <BsPrescription /> &nbsp;
        </span>
        Prescription List
      </a>

      {departmentFormList.map((data) => (
        <a href={`${config.baseUrl}viewRecord/${data.formId}/${recordId}/${data.name}/isPatiendSearch`} className="nav-link mt-3" key={data.formId}>
          <span>
            <BsList />
          </span>&nbsp;
          {capitalizeFirstWord(data.formTypeName)}
        </a>
      ))}

      {assignedFormsTypes.length > 0 &&
        assignedFormsTypes.map((data) => (
          <div key={data.formTypeId}>
            <div className="d-flex flex-row align-items-center mt-3">
              <p className="mb-0 pe-5" style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggle(data.formTypeId);
                  openFormList(data.formTypeId);
                }}
                aria-controls="example-collapse-text"
                aria-expanded={openForm === data.formTypeId}>
                {data.formTypeName}
              </p>
              <BsChevronDown />
            </div>

            <Collapse in={openForm === data.formTypeId}>
              <div>
                {assignedFormsList.map((form) => (
                  <a href={`${config.baseUrl}viewTreatment/${form.formId}/${recordId}/${form.name}`} className="nav-link mt-3" key={form.formId}>
                    <span>
                      <BsList />
                    </span>&nbsp;
                    {capitalizeFirstWord(form.name)}
                  </a>
                ))}
              </div>
            </Collapse>
          </div>
        ))}
    </div>
  );
};

export default AssignedFormSidebar;