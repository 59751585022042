import React, { forwardRef, useEffect, useState } from "react";
import { decryptData } from "./admin/decrypt";
import { getAllReportContentAction, getPatiendInfoAction } from '../Action/admin.action';

const today = new Date();
const options = { day: '2-digit', month: 'long', year: 'numeric' };
const todayDate = today.toLocaleDateString('en-GB', options);
let MRNNumber = localStorage.getItem("mrnNumber");
const PrintPatientReport = forwardRef(
  (
    {
      patientData,
      patientDataPre,
      patientDataDuring,
      formDetails,
      formDetailsPre,
      formDetailsDuring
    },
    ref
  ) => {
    const [reportData, setReportData] = useState('');
    const [patientInfo, setPatientInfo] = useState('');

    useEffect(() => {
      getReportContentList();
      getPatiendInfo();
    }, []);

    const getReportContentList = async () => {
      let res = await getAllReportContentAction();
      if (res.success) {
        let decryptedData = decryptData(res.data);
        setReportData(decryptedData)
      }
    };

    const getPatiendInfo = async () => {
      let res = await getPatiendInfoAction({ mrnNumber: MRNNumber });
      if (res.success) {
        let decryptedData = decryptData(res.data);
        const requiredIds = [16, 17, 18];
        const results = requiredIds.map(id => {
          const item = decryptedData.find(entry => entry.formDetailsId === id);
          return item ? item.fieldValue : ``;
        });
        setPatientInfo(results)
      }
    };

    return (
      <div ref={ref} className="printableview-content">

        <div
          className="custom-header d-flex justify-content-between"
          style={{ borderBottom: "1px solid #3EC4F1", padding: "10px" }}
        >
          <img
            src={reportData?.logo || "assets/images/logo2.png"}
            className="img-fluid"
            style={{ width: 200, height: 150, marginTop: "-20px" }}
            alt=""
          />
          <div>
            <p className="mb-0 fs12"><b> Address: </b> {reportData?.address || ''}</p>
            <p className="mb-0 fs12"><b> Phone Number:</b> {reportData?.contactNumber || ''}</p>
            <p className="mb-0 fs12"><b>Patient Name:</b> {`${patientInfo[0]} ${patientInfo[1]} ${patientInfo[2]}`}</p>
            <p className="mb-0 fs12"><b>MRN Number:</b> {MRNNumber}</p>
            <p className="mb-0 fs12"><b> Date: </b> {todayDate}</p>
          </div>
        </div>
        <br />

        <div className="header text-center">
          <h3
            style={{
              textDecoration: "underline",
              fontWeight: 500,
              color: "#1d1d1d",
            }}
          >
            Hemodialysis Treatment Report
          </h3>
        </div>
        <br />
        {/* Pre-HD Data */}
        <h2>Pre-HD Treatment</h2>
        {patientDataPre.length > 0 ? (
          patientDataPre.map((patient, patientIndex) => (
            <div key={patientIndex} className="patient-section">
              <h3>Record {patientIndex + 1}</h3>
              {formDetailsPre.map((field, index) => {
                const fieldValue = patient[field.id];
                return (
                  <div
                    key={index}
                    className="field d-flex"
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      lineHeight: "1.5",
                    }}
                  >
                    <strong style={{ flex: "0 0 40%" }}>{field.fieldName}:</strong>
                    <span style={{ flex: "1" }}>{fieldValue ?? "N/A"}</span>
                  </div>
                );
              })}

              <hr />
            </div>
          ))
        ) : (
          <p>No Pre-HD data available</p>
        )}

        {/* During-HD Data */}
        <h2>During-HD Treatment</h2>
        {patientDataDuring.length > 0 ? (
          patientDataDuring.map((patient, patientIndex) => (
            <div key={patientIndex} className="patient-section">
              <h3># {patientIndex + 1}</h3>
              {formDetailsDuring.map((field, index) => {
                const fieldValue = patient[field.id];
                return (
                  <div
                    key={index}
                    className="field d-flex"
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      lineHeight: "1.5",
                    }}
                  >
                    <strong style={{ flex: "0 0 40%" }}>{field.fieldName}:</strong>
                    <span style={{ flex: "1" }}>{fieldValue ?? "N/A"}</span>
                  </div>
                );
              })}
              <hr />
            </div>
          ))
        ) : (
          <p>No During-HD data available</p>
        )}

        {/* Post-HD Data */}
        <h2>Post-HD Treatment</h2>
        {patientData.length > 0 ? (
          patientData.map((patient, patientIndex) => (
            <div key={patientIndex} className="patient-section">
              <h3># {patientIndex + 1}</h3>
              {formDetails.map((field, index) => {
                const fieldValue = patient[field.id];
                return (
                  <div
                    key={index}
                    className="field d-flex"
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      lineHeight: "1.5",
                    }}
                  >
                    <strong style={{ flex: "0 0 40%" }}>{field.fieldName}:</strong>
                    <span style={{ flex: "1" }}>{fieldValue ?? "N/A"}</span>
                  </div>
                );
              })}
              <hr />
            </div>
          ))
        ) : (
          <p>No Post-HD data available</p>
        )}

        {/* Footer Section */}
        <div className="footer">
          <p>{reportData?.footerDescription || ''}</p>
        </div>
      </div>
    );
  }
);

export default PrintPatientReport;
