import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import '../componentCss/webform.css';
import { useNavigate } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import 'react-toastify/dist/ReactToastify.css';
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from '../../config/config';
import { Link } from "react-router-dom";
import { getAllReportContentAction, updateReportContent } from '../../Action/admin.action';
import toast from 'react-hot-toast';
import { decryptData } from './decrypt';

const ReportContent = () => {
    const navigate = useNavigate();
    const [formData, setForm] = useState({ 
        name: '', 
        contactNumber: '', 
        address: '', 
        footerDescription: '', 
        logo: null,
        id:""
    });
    
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getReportContentList();
    }, []);

    const getReportContentList = async () => {
        let res = await getAllReportContentAction();
        if (res.success) {
            let decryptedData = decryptData(res.data);
            setForm({
                name: decryptedData.name || '',
                contactNumber: decryptedData.contactNumber || '',
                address: decryptedData.address || '',
                footerDescription: decryptedData.footerDescription || '',
                logo: decryptedData.logo || '',
                id: decryptedData.id || ''

            });
            if (decryptedData.logo) {
                setSelectedImage(`${decryptedData.logo}`);
            }
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setForm({ ...formData, logo: file });
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.name.trim()) newErrors.name = "Department name is required";
        if (!formData.contactNumber.trim()) newErrors.contactNumber = "Contact number is required";
        if (!formData.address.trim()) newErrors.address = "Address is required";
        if (!formData.footerDescription.trim()) newErrors.footerDescription = "Footer description is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);
      
        
        let res = await updateReportContent(formData);

        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        } else {
            setIsLoading(false);
            toast.error(res.msg);
        }
    };

    return (
        <>
            <Dashboardheader />
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12}>
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Edit Report Content</h5>
                                </div>
                                <Link to={`${config.baseUrl}user-group`}>
                                    <span style={{ float: 'right' }}>Back <BsArrowLeftCircleFill /></span>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className='formDiv'>
                                    <Form onSubmit={handleSubmit}>
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <Form.Label>Department Name</Form.Label>
                                                <input className='form-control' type="text" name='name' value={formData.name} onChange={(e) => setForm({ ...formData, name: e.target.value })} placeholder="Enter department name" />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <Form.Label>Contact Number</Form.Label>
                                                <input className='form-control' type="text" name='contactNumber' value={formData.contactNumber} onChange={(e) => setForm({ ...formData, contactNumber: e.target.value })} placeholder="Enter contact number" />
                                                {errors.contactNumber && <div className="text-danger">{errors.contactNumber}</div>}
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <Form.Label>Address</Form.Label>
                                                <input className='form-control' type="text" name='address' value={formData.address} onChange={(e) => setForm({ ...formData, address: e.target.value })} placeholder="Enter address" />
                                                {errors.address && <div className="text-danger">{errors.address}</div>}
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <Form.Label>Footer Description</Form.Label>
                                                <textarea className='form-control' name='footerDescription' value={formData.footerDescription} onChange={(e) => setForm({ ...formData, footerDescription: e.target.value })} placeholder="Enter footer description" />
                                                {errors.footerDescription && <div className="text-danger">{errors.footerDescription}</div>}
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <Form.Label>Logo</Form.Label>
                                                <input type="file" className="form-control" accept="image/*" onChange={handleImageChange} />
                                                <br/>
                                                {selectedImage && <img src={selectedImage} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "5px" }} />}
                                            </Form.Group>
                                        </Row>
                                        <button type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6' disabled={isLoading}>{isLoading ? 'Loading...' : 'Update'}</button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ReportContent;