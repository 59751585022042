import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { getFormDetailsAction } from "../../../Action/admin.action";
import propertise from '../../../config/properties';
import "react-toastify/dist/ReactToastify.css";
import { decryptData } from "../../admin/decrypt";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

const MedpasFormModel = ({ isOpenRxFormModel, handleClose, handleSubmitMedpasDetail }) => {
  const [formDetails, setFormDetails] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getFormDetailsAPI();
  }, []);

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: propertise.medPassFormId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
    }
  };

  const handleSubmit = () => {
    if (formData.length <= 0) {
      toast.error('Please fill the form')
      return;
    }
    handleSubmitMedpasDetail(formData);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  };

  return (
    <>
      <Modal show={isOpenRxFormModel} className='modal-lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Medpas details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="reportTitle" className='mt-2'>
              <Row>
                <Col lg={12} md={12} sm={12} className="">
                  <div className="formDiv">
                    <Form>
                      <Row className="mb-4 d-flex justify-content-center">
                        {formDetails.length > 0
                          ? formDetails.filter((item) => {
                            // Hide "Scheduling" and "Administration time" when "Medication Type" is "Medication PRN"
                            if (formData["Medication Type"] === "Medication PRN" && (item.fieldName === "Scheduling" || item.fieldName === "Administration time")) {
                              return false;
                            }
                            return true;
                          })
                            .map((item) => (

                              <div className="col-md-6 mt-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                  {item.fieldtype == "selectManual" ? (
                                    <>
                                      <label>{item.fieldName}</label>
                                      {JSON.parse(item.radioCheckboxValue).length > 0 ?
                                        <select
                                          className="form-control"
                                          name={item.fieldName}
                                          onChange={handleChange}
                                        >
                                          {item.id != 2241 &&
                                            <option value="">-Select-</option>
                                          }
                                          {JSON.parse(item.radioCheckboxValue).map(
                                            (radioData) => (
                                              <>
                                                <option value={radioData}>
                                                  {radioData}
                                                </option>
                                              </>
                                            )
                                          )}
                                        </select>
                                        : ""}
                                    </>
                                  ) :
                                    <>
                                      {formData["Medication Type"] == "Medication PRN"}
                                      <label>{item.fieldName}</label>
                                      <Form.Control
                                        onChange={handleChange}
                                        type={item.fieldtype}
                                        name={item.fieldName}
                                        placeholder={`Enter ${item.fieldName}`}
                                      />
                                    </>
                                  }
                                </Form.Group>
                              </div>
                            ))
                          : ""}
                      </Row>
                      <br />
                    </Form>
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MedpasFormModel;
