import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import "../componentCss/webform.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import AddendumModal from "./ModalComponents/AddendumModal";
import MedpasFormModel from "./ModalComponents/MedpasFormModel";
import "../componentCss/sidebar.css";
import Swal from "sweetalert2";
import PrintPatientReport from "../PrintPatientReport";

import {
  getFormDetailsAction,
  insertFormDataAction,
  insertFormDataActionForSubForm,
  getPatientDataByMRNAction,
  getpatientDetailsById,
  getFormDataByMasterAction,
  updateRecordStatus,
} from "../../Action/admin.action";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import MyKonvaComponent from "./ModalComponents/MyKonvaComponent";
import propertise from "../../config/properties";
import { decryptData } from "./decrypt";
import config from "../../config/config";
import { addReportsReason } from "../../redux/slices/reportsSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const Webform = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [multipleValues, setMultipleValues] = useState({});
  let { formId, id, patientId, formName, recordId } = useParams();
  let MRNNumber = localStorage.getItem("mrnNumber");
  const [formFields, setFieldsData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  let [formValues, setFormValues] = useState({});
  const [draftData, setDraftData] = useState([]);
  const [draftDataList, setDraftDataList] = useState([]);
  const [formDetails, setFormDetails] = useState([]);
  const [staffFormEditPermission, setStaffFormEditPermission] = useState();
  const [subFormValues, setSubFormValues] = useState([]);
  const [subFormDetails, setSubFormDetails] = useState([]);
  const [formPreviewValues, setFormPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [formDetailIdForForm, setFormDetailsId] = useState("");
  const [patientDetails, setPatientDetails] = useState({
    emrNumber: "",
    mrnNumber: MRNNumber,
  });
  const [stageRefs, setStageRefs] = useState([]);
  const [currentRawData, setCurrentRawData] = useState(null);
  const [currentDrugRawIndex, setCurrentDrugRawIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [masterDataDropDown, setMasterDataDropDown] = useState([]);
  const [tableData, setTableData] = useState("");
  const [patientDataPre, setPatientDataPre] = useState("");
  const [patientDataDuring, setPatientDataDuring] = useState("");
  const [formDetailsPre, setFormDetailsPre] = useState([]);
  const [formDetailsDuring, setFormDetailsDuring] = useState([]);
  const [isOpenRxFormModel, setOpenRxFormModel] = useState(false);

  const allDataRef = useRef();
  const printRef = useRef();

  useEffect(() => {
    const refsArray = formDetails.map(() => React.createRef());
    setStageRefs(refsArray);
  }, [formDetails]);

  useEffect(() => {
    getFormDetailsAPI();
    getPatientDataAPI();
    getFormDataListAPI();
    getFormDataListAPIPre();
    getFormDataListAPIDuring();
    getFormDetailsAPIPre();
    getFormDetailsAPIDuring();
  }, []);

  const getFormDataListAPI = async () => {
    let data = {
      mrnNumber: MRNNumber,
      formId: formId,
    };
    let res = await getpatientDetailsById(data);
    if (res.success) {
      let data = decryptData(res.data);
      setFieldsData(data.fields);
      setPatientData(data.records);
    }
  };

  const getFormDataListAPIPre = async () => {
    let data = {
      mrnNumber: MRNNumber,
      formId: 121,
    };
    let res = await getpatientDetailsById(data);
    if (res.success) {
      let data = decryptData(res.data);
      setPatientDataPre(data.records);
    }
  };

  const getFormDataListAPIDuring = async () => {
    let data = {
      mrnNumber: MRNNumber,
      formId: 122,
    };
    let res = await getpatientDetailsById(data);
    if (res.success) {
      let data = decryptData(res.data);
      setPatientDataDuring(data.records);
    }
  };

  let getPatientDataAPI = async () => {
    let res = await getPatientDataByMRNAction({ mrnNumber: MRNNumber });
    if (res.success) {
      let data = decryptData(res.data);
      setPatientDetails({
        ...patientDetails,
        ["emrNumber"]: data.emrNumber,
      });
    }
  };

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      setStaffFormEditPermission(decryptData(res.staffFormEditPermission));
      let data = decryptData(res.data);
      data.forEach((item, index) => {
        if (item.fieldTypeId == 20) {
          getFormDataByMasterAPI(
            item.dropdownFormId,
            item.dropdownFieldId,
            index
          );
        }
      });
    }
  };

  let getFormDetailsAPIPre = async () => {
    let res = await getFormDetailsAction({ id: 121 });
    if (res.success) {
      setFormDetailsPre(decryptData(res.data));
    }
  };

  let getFormDetailsAPIDuring = async () => {
    let res = await getFormDetailsAction({ id: 122 });
    if (res.success) {
      setFormDetailsDuring(decryptData(res.data));
    }
  };

  let getFormDataByMasterAPI = async (
    dropdownFormId,
    dropdownFieldId,
    index
  ) => {
    let res = await getFormDataByMasterAction({
      dropdownFormId: dropdownFormId,
      dropdownFieldId: dropdownFieldId,
    });
    if (res.success) {
      setMasterDataDropDown((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = decryptData(res.data);
        return updatedState;
      });
    }
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setPatientDetails({
      ...patientDetails,
      [name]: value,
    });
  };

  const handleChange = async (e, item) => {
    let tempFormDetails = [...formDetails];
    let subFormData = [...subFormDetails];
    let tempSubFormValues = [...subFormValues];

    const { name, value, type, checked, id } = e.target;

    let newValue = "";
    let newValue1 = "";
    if (type === "checkbox") {
      let updatedValues = formValues[name] || [];
      if (checked) {
        updatedValues.push(value);
      } else {
        updatedValues = updatedValues.filter((val) => val !== value);
      }
      newValue = updatedValues;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else if (name == "assignTo") {
      const [id, ...firstNameParts] = e.target.value.split("_");
      const firstName = firstNameParts.join("_");

      newValue = id;
      newValue1 = firstName;
    } else {
      newValue = value;
    }
    if (type == "radio") {
      if (
        item.dropdownFormId > 0 &&
        (value.trim() == "Yes" ||
          value.trim() == "YES" ||
          value.trim() == "yes")
      ) {
        tempSubFormValues.push({
          subFormId: item.dropdownFormId,
          id,
          formDetailId: item.id,
        });
        setFormDetailsId(item.id);
        setSubFormValues(tempSubFormValues);
        let res = await getFormDetailsAction({ id: item.dropdownFormId });
        if (res.success) {
          let resForm = tempFormDetails.find((data) => data.id === item.id);
          resForm.dropDownFormData = decryptData(res.data);
          let obj = decryptData(res.data);
          obj.dropdownFormId = item.dropdownFormId;
          setFormDetails(tempFormDetails);
          subFormData.push(obj);
          setSubFormDetails(subFormData);
        }
      } else {
        let resForm = tempFormDetails.find((data) => data.id === item.id);
        let getSubFormIndex = subFormDetails.findIndex(
          (data) => data.dropdownFormId === item.dropdownFormId
        );
        subFormDetails.splice(getSubFormIndex, 1);

        resForm.dropDownFormData = "";
        setFormDetails(tempFormDetails);
        setSubFormDetails(subFormDetails);
      }
    }
    if (draftDataList.length > 0) {
      setDraftData({ ...draftData, [name]: newValue });
    } else {

      let extractedData = []
      if (e.target.data) {
        extractedData = e.target.data.map(item => ({
          drugcode: item.data.drugcode,
          packagename: item.data.packagename
        }));
      }

      setFormValues({
        ...formValues, [name]:
          item?.fieldTypeId == propertise.masterDataDropdownList ? JSON.stringify(extractedData) : newValue,
      });
    }
    if (item?.fieldTypeId == propertise.masterDataDropdownList) {
      const existingData = [...e.target.data];
      existingData[e.target.data.length - 1].data = {
        ...existingData[e.target.data.length - 1].data,
      };

      setTableData(existingData);
    }
    setFormPreviewValues({
      ...formPreviewValues,
      [id]: newValue1 !== "" ? newValue1 : newValue,
    });
  };

  const handleChangeForSubForm = async (e, formId) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else {
      newValue = value;
    }

    let getIndex = subFormValues.findIndex((data) => data.subFormId === formId);
    if (getIndex > -1) {
      const updatedForm = {
        ...subFormValues[getIndex],
        [name]: newValue,
        formDetailId: formDetailIdForForm,
        formId: formId,
        subFormId: formId,
      };
      const updatedSubFormValues = [...subFormValues];
      updatedSubFormValues[getIndex] = updatedForm;
      setSubFormValues(updatedSubFormValues);
      setFormPreviewValues({ ...formPreviewValues, [id]: newValue });
    }
  };

  const resetForm = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.location.reload();
    });
  };

  const convertArrayToJSONString = (data) => {
    const updatedData = {};
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        updatedData[key] = JSON.stringify(data[key]);
      } else {
        updatedData[key] = data[key];
      }
    });

    return updatedData;
  };

  const handleSubmit = async (e, submitType, redirectionType = 0) => {
    e.preventDefault();
    // setIsLoading(true);

    formValues.formId = formId;
    formValues.referenceFormId = id;
    formValues.pid = patientId;
    formValues.submitType = 0; // 0 - Draft, 1-Active
    formValues.mrnNumber = patientDetails?.mrnNumber;
    formValues.emrNumber = patientDetails?.emrNumber;
    Object.keys(multipleValues).forEach((fieldId) => {
      formValues[fieldId] = JSON.stringify(multipleValues[fieldId]);
    });

    if (formId == propertise.medPassDrugFormId) {
      let drugList = JSON.parse(formValues[propertise.drugListFieldId]);
      let drugRecordList = drugList.map(item => {
        let matchedItem = tableData.find(rx => rx.data.drugcode === item.drugcode);
        return matchedItem ? { ...item, rxData: JSON.parse(matchedItem.data.rxData) } : item;
      });
      formValues[propertise.drugListFieldId] = JSON.stringify(drugRecordList)
    }

    const formFilledData = convertArrayToJSONString(formValues);

    let res = await insertFormDataAction({ formValues: formFilledData });
    if (res.success) {
      if (subFormValues.length > 0) {
        await insertFormDataActionForSubForm({ subFormValues: subFormValues });
      }
      toast.success(res.msg);
      if (redirectionType == 1) {
        let patientid = res.data[0].id;
        if (formId != propertise.patientAdmit) {
          patientid = patientId;
        }
        if (formId == 3 || formId == 4 || formId == 5) {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}form-submission/${formDetails[0].actionFormId}/0/${patientid}`;
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}viewTreatment/${formDetails[0]?.actionFormId}/0/${formDetails[0]?.actionFormName}`;
          }, 2000);
        }
      } else {
        if (formId == 4) {
          setTimeout(() => {
            window.location.href = `${config.baseUrl}staffDashboard`;
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      setIsLoading(false);
      toast.error(res.msg);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSave = (e, index) => {
    e.preventDefault();
    const stageRef = stageRefs[index].current;
    const uri = stageRef.toDataURL();
    const file = dataURLtoFile(uri, `${stageRef.attrs.name}.png`);
    if (file) {
      toast.success("Image Saved..");
      setFormValues({ ...formValues, [stageRef.attrs.name]: file });
      setFormPreviewValues({
        ...formPreviewValues,
        [stageRef.attrs.id]: file,
      });
    }
  };

  const formPreview = (e, index) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const handleUpdateStatus = async (e, rowData) => {
    if (e.target.value == 4) {
      navigate(
        `${config.baseUrl}viewRecord/${rowData.formId}/${rowData.recordId}/${formName}`
      );
    } else if (e.target.value == 5) {
      navigate(
        `${config.baseUrl}copyTemplate/${rowData.formId}/${rowData.recordId}/${formName}`
      );
    } else if (rowData.status == 2) {
      toast.error("Record has been already invalidated.");
    } else if (rowData.status == 3) {
      toast.error("Record has been already addendum.");
    } else if (e.target.value == 1) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to update the record status?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        let res = await updateRecordStatus({
          formId: rowData.formId,
          recordId: rowData.recordId,
        });
        if (res.success) {
          getFormDataListAPI();
        } else {
          Swal.fire("Error!", "Failed to update the record status.", "error");
        }
      }
    } else {
      if (rowData.remainingHour <= 0 && rowData.isLateEntry == 1) {
        toast.error("Not accessible.");
        return;
      }

      setCurrentRawData(rowData);
      if (e.target.value == 3 || e.target.value == 2 || e.target.value == 6) {
        setShowModal(true);
      }
      setStatus(e.target.value);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setOpenRxFormModel(false)
  };

  const handleSubmitInvalidAddendum = async (data) => {
    const res = await dispatch(addReportsReason(data));
    setShowModal(!showModal);
    if (res && res.payload && res.payload?.success) {
      toast.success(res.payload?.msg);
      getFormDataListAPI();
    }
  };

  const handleMultipleValueChange = (e, fieldId, index) => {
    const { value } = e.target;
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!updatedValues[fieldId]) {
        updatedValues[fieldId] = [];
      }
      updatedValues[fieldId][index] = value;
      return updatedValues;
    });
  };

  const addMoreFields = (fieldId) => {
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!updatedValues[fieldId]) {
        updatedValues[fieldId] = [];
      }
      updatedValues[fieldId].push("");
      return updatedValues;
    });
  };

  const removeField = (fieldId, index) => {
    setMultipleValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (updatedValues[fieldId]) {
        updatedValues[fieldId].splice(index, 1);
      }
      return updatedValues;
    });
  };

  const handleSubmitMedpasDetail = async (data) => {
    const updatedDetails = [...tableData];
    updatedDetails[currentDrugRawIndex].data["rxData"] = JSON.stringify(data);
    setTableData(updatedDetails);
    setOpenRxFormModel(false);
  };

  const openRxFormModel = (index, data) => {
    setCurrentDrugRawIndex(index)
    setOpenRxFormModel(true);
  };

  function FormPreview({ formData, onCancel, onSubmit }) {
    const [imageUrls, setImageUrls] = useState({});
    useEffect(() => {
      const newImageUrls = {};
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          newImageUrls[key] = URL.createObjectURL(value);
          return () => URL.revokeObjectURL(newImageUrls[key]);
        }
      });
      setImageUrls(newImageUrls);
    }, [formData]);

    const getDisplayValue = (key, value) => {
      if (value instanceof File) {
        if (!imageUrls[key]) {
          const url = URL.createObjectURL(value);
          setImageUrls((prevUrls) => ({
            ...prevUrls,
            [key]: url,
          }));
          return url;
        }
        return imageUrls[key];
      }
      return value;
    };

    return (
      <div className="form-preview-container">
        <h2 className="text-center mb-4">Form Preview</h2>

        <div className="card shadow p-3 mb-5 bg-white rounded">
          <ul className="list-group list-group-flush">
            {draftDataList.length > 0
              ? Object.entries(draftData).map(([key, value]) => (
                <li
                  key={key}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <strong>{key}:</strong>
                  {value instanceof File ? (
                    <div>
                      <img
                        src={getDisplayValue(key, value)}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{ maxWidth: "200px", height: "auto" }}
                      />
                    </div>
                  ) : (
                    <>
                      <span>{getDisplayValue(key, value)}</span>
                    </>
                  )}
                </li>
              ))
              : formDetails.map(
                (data) =>
                  formValues[data.id] && (
                    <li
                      key={data.id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <strong>{data.fieldName}:</strong>
                      {formValues[data.id] instanceof File ? (
                        <div>
                          <img
                            src={getDisplayValue(
                              data.id,
                              formValues[data.id]
                            )}
                            alt="Preview"
                            className="img-thumbnail"
                            style={{ maxWidth: "200px", height: "auto" }}
                          />
                        </div>
                      ) : (
                        <>
                          <span>
                            {getDisplayValue(data.id, formValues[data.id])}
                          </span>
                          {/* <div style={{ overflowX: 'auto', maxHeight: '400px', border: '1px solid #ddd' }}>
                          <table className="table" style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                {Object.keys(JSON.parse(formValues[data.id])[0].data).map((key, index) => (
                                  <th key={index} style={{ textAlign: 'left' }}>
                                    {key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.slice(1)}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {JSON.parse(formValues[data.id]).map((item, index) => (
                                <tr key={index}>
                                  {Object.values(item.data).map((value, index) => (
                                    <td key={index}>
                                      {value !== null && value !== undefined ? value.toString() : "-"}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div> */}
                        </>
                      )}
                    </li>
                  )
              )}
          </ul>
        </div>

        <div className="button-container text-center">
          <button className="btn btn-secondary mt-3 me-2" onClick={onCancel}>
            Cancel
          </button>
          <button
            disabled={isLoading == true ? true : false}
            className="btn btn-primary mt-3"
            onClick={(e) => handleSubmit(e, 1, 0)}
          >
            {isLoading == true ? "Loading..." : "Submit"}
          </button>
          &nbsp;
          {formDetails[0].isCallToAction === 1 && (
            <button
              disabled={isLoading == true ? true : false}
              className="btn btn-primary mt-3"
              onClick={(e) => handleSubmit(e, 1, 1)}
            >
              {isLoading == true ? "Loading..." : "Submit & Redirect"}
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={3} md={3} sm={3} className="">
                <AssignedFormSidebar />
              </Col>
              <Col lg={9} md={9} sm={9} className="">
                <h3>{formName}</h3>
                <Link to={`${config.baseUrl}staffDashboard`}>
                  <span style={{ float: "right", marginTop: "-15px" }}>
                    Back
                  </span>
                </Link>
                <div className="formDiv">
                  {!showPreview ? (
                    <>
                      <Form>
                        <Row className="mb-4">
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-3 fieldbox h-auto px-2">
                                <Form.Label>Emirates Id Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Emirates Id Number"
                                  className="form-control"
                                  name="emrNumber"
                                  readOnly={
                                    formId == propertise.patientAdmit
                                      ? false
                                      : true
                                  }
                                  onChange={handleChangeData}
                                  value={patientDetails?.emrNumber}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3 fieldbox h-auto px-2">
                                <Form.Label>MRN Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter MRN Number"
                                  className="form-control"
                                  name="mrnNumber"
                                  value={patientDetails?.mrnNumber}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          {formDetails.length > 0
                            ? formDetails.map((item, index) =>
                              (formId == 1 &&
                                item.fieldName == "emrNumber") ||
                                item.fieldName == "Mrn Number" ||
                                item.fieldName == "Patient Satus" ? null : (
                                <div className={item.fieldTypeId == propertise.formHeading ? "col-md-12 mt-3" : "col-md-6 mt-3"} key={item.id} >
                                  {item.fieldTypeId === propertise.formHeading && ( // Form heading
                                    <Row>
                                      <Col lg={12} md={12} sm={12}>
                                        <div
                                          style={{
                                            background:
                                              "linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%)",
                                            color: "white",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            textShadow:
                                              "2px 1px 4px rgba(0, 0, 0, 0.25)",
                                          }}
                                        >
                                          <span>{item.fieldName}</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}

                                  <Form.Group
                                    as={Col}
                                    controlId="formGridEmail"
                                  >
                                    <Form.Label>
                                      {item.fieldtype !== "btn" ? item.fieldName : ""}
                                    </Form.Label>
                                    <br />
                                    {item.fieldtype === "select" ? (
                                      <select
                                        className="form-control"
                                        id={item.fieldName}
                                        name={item.id}
                                        onChange={handleChange}
                                        value={draftData[item.id]}
                                      >
                                        <option value={0}>
                                          Select Department
                                        </option>
                                        {item.dropdownValue != null
                                          ? JSON.parse(
                                            item.dropdownValue
                                          ).map((itemDropdown) => (
                                            <option
                                              key={itemDropdown.fieldValue}
                                              value={
                                                itemDropdown.fieldValue
                                              }
                                            >
                                              {itemDropdown.fieldValue}
                                            </option>
                                          ))
                                          : ""}
                                      </select>
                                    ) : item.fieldtype === "selectManual" ? (
                                      <select
                                        className="form-control"
                                        id={item.fieldName}
                                        name={item.id}
                                        onChange={handleChange}
                                        value={draftData[item.id]}
                                      >
                                        <option value={0}>-Select-</option>
                                        {item.radioCheckboxValue != null
                                          ? JSON.parse(
                                            item.radioCheckboxValue
                                          ).map((itemDropdown) => (
                                            <option
                                              key={itemDropdown}
                                              value={itemDropdown}
                                            >
                                              {itemDropdown}
                                            </option>
                                          ))
                                          : ""}
                                      </select>
                                    ) : item.fieldTypeId == propertise.masterDataDropdownList ? (  // Master data dropdown
                                      <Select
                                        id={item.fieldName}
                                        name={item.id}
                                        isMulti
                                        options={masterDataDropDown[
                                          index
                                        ]?.map((itemDropdown) => ({
                                          value:
                                            itemDropdown[
                                            item.dropdownFieldId
                                            ],
                                          label:
                                            itemDropdown[
                                            item.dropdownFieldId
                                            ],
                                          data: itemDropdown,
                                        }))}
                                        onChange={(selectedOption) =>
                                          handleChange(
                                            {
                                              target: {
                                                name: item.id,
                                                value: selectedOption?.value,
                                                data: selectedOption || "",
                                              },
                                            },
                                            item
                                          )
                                        }
                                        placeholder="-Select an Option-"
                                        isClearable
                                      />
                                    ) : item.fieldtype === "radio" ||
                                      item.fieldtype === "checkbox" ? (
                                      <>
                                        {item.radioCheckboxValue &&
                                          JSON.parse(
                                            item.radioCheckboxValue
                                          ).map((radioData) => (
                                            <React.Fragment key={radioData}>
                                              <input
                                                onChange={(e) =>
                                                  handleChange(e, item)
                                                }
                                                value={radioData}
                                                type={item.fieldtype}
                                                name={item.id}
                                                id={item.fieldName}
                                              />{" "}
                                              {radioData} <br />
                                            </React.Fragment>
                                          ))}
                                        {item.dropDownFormData ? (
                                          <>
                                            <div
                                              style={{
                                                border: "1px solid grey ",
                                                padding: "15px",
                                              }}
                                            >
                                              <Row className="mb-4">
                                                {item.dropDownFormData
                                                  ? item.dropDownFormData.map(
                                                    (item) => (
                                                      <div className="col-md-6 mt-4">
                                                        <Form.Group
                                                          as={Col}
                                                          controlId="formGridEmail"
                                                        >
                                                          <Form.Label>
                                                            {item.fieldtype !=
                                                              "btn"
                                                              ? item.fieldName
                                                              : ""}
                                                          </Form.Label>{" "}
                                                          <br />
                                                          {item.fieldtype ==
                                                            "select" ? (
                                                            <select
                                                              className="form-control"
                                                              id={
                                                                item.fieldName
                                                              }
                                                              name={item.id}
                                                              onChange={(
                                                                e
                                                              ) =>
                                                                handleChangeForSubForm(
                                                                  e,
                                                                  item.formId
                                                                )
                                                              }
                                                            >
                                                              <option
                                                                value={0}
                                                              >
                                                                Select
                                                                Department
                                                              </option>
                                                              {item.dropdownValue !=
                                                                null
                                                                ? JSON.parse(
                                                                  item.dropdownValue
                                                                ).map(
                                                                  (
                                                                    itemDropdown
                                                                  ) => (
                                                                    <>
                                                                      <option
                                                                        value={
                                                                          itemDropdown.fieldValue
                                                                        }
                                                                      >
                                                                        {
                                                                          itemDropdown.fieldValue
                                                                        }
                                                                      </option>
                                                                    </>
                                                                  )
                                                                )
                                                                : ""}
                                                            </select>
                                                          ) : item.fieldtype ==
                                                            "radio" ||
                                                            item.fieldtype ==
                                                            "checkbox" ? (
                                                            JSON.parse(
                                                              item.radioCheckboxValue
                                                            ).map(
                                                              (
                                                                radioData
                                                              ) => (
                                                                <>
                                                                  <input
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeForSubForm(
                                                                        e,
                                                                        item.formId
                                                                      )
                                                                    }
                                                                    value={
                                                                      radioData
                                                                    }
                                                                    type={
                                                                      item.fieldtype
                                                                    }
                                                                    name={
                                                                      item.id
                                                                    }
                                                                    id={
                                                                      item.fieldName
                                                                    }
                                                                  />{" "}
                                                                  {
                                                                    radioData
                                                                  }{" "}
                                                                  <br />
                                                                </>
                                                              )
                                                            )
                                                          ) : item.fieldtype ==
                                                            "btn" ? (
                                                            ""
                                                          ) : (
                                                            <Form.Control
                                                              onChange={(
                                                                e
                                                              ) =>
                                                                handleChangeForSubForm(
                                                                  e,
                                                                  item.formId
                                                                )
                                                              }
                                                              required={
                                                                item.isMandatory
                                                                  ? true
                                                                  : false
                                                              }
                                                              type={
                                                                item.fieldtype
                                                              }
                                                              name={item.id}
                                                              placeholder={`Enter ${item.fieldName}`}
                                                              id={
                                                                item.fieldName
                                                              }
                                                              value={
                                                                formValues[
                                                                item?.id
                                                                ]
                                                              }
                                                            />
                                                          )}
                                                        </Form.Group>
                                                      </div>
                                                    )
                                                  )
                                                  : ""}
                                              </Row>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : item.fieldtype === "btn" ? (
                                      ""
                                    ) : item.fieldtype === "canvas" ? (
                                      <>
                                        <MyKonvaComponent
                                          name={item.id}
                                          id={item.fieldName}
                                          imageUrl={item.fieldValue}
                                          height={400}
                                          width={700}
                                          stageRef={stageRefs[index]}
                                          index={index}
                                          handleSave={handleSave}
                                        />
                                      </>
                                    ) : item.fieldtype == "multipleValue" ? (
                                      <>
                                        {
                                          (multipleValues[item.id] =
                                            multipleValues[item.id] || [""])
                                        }
                                        {multipleValues[item.id].map(
                                          (value, index) => (
                                            <div
                                              key={index}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Form.Control
                                                onChange={(e) =>
                                                  handleMultipleValueChange(
                                                    e,
                                                    item.id,
                                                    index
                                                  )
                                                }
                                                type="text"
                                                name={`${item.id}_${index}`}
                                                value={value}
                                                placeholder={`Enter ${item.fieldName}`}
                                              />
                                              {multipleValues[item.id]
                                                .length > 1 && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() =>
                                                      removeField(
                                                        item.id,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Remove
                                                  </button>
                                                )}
                                            </div>
                                          )
                                        )}
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary mt-1"
                                          onClick={() =>
                                            addMoreFields(item.id)
                                          }
                                        >
                                          Add More
                                        </button>
                                      </>
                                    ) : (
                                      <Form.Control
                                        onChange={handleChange}
                                        required={
                                          item.isMandatory ? true : false
                                        }
                                        type={item.fieldtype}
                                        name={item.id}
                                        value={
                                          item.fieldtype !== "file"
                                            ? formId == 1 &&
                                              item.fieldName == "MRN Number"
                                              ? MRNNumber
                                              : draftData[item.id]
                                            : undefined
                                        }
                                        placeholder={`Enter ${item.fieldName}`}
                                      />
                                    )}
                                  </Form.Group>
                                </div>
                              )
                            )
                            : ""}
                          &nbsp;
                        </Row>

                        <br />
                        <br />
                        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                          {tableData ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  {tableData.length > 0 &&
                                    Object.keys(tableData[0].data).map(
                                      (key, index) => (
                                        <th scope="col" key={index}>
                                          {key.charAt(0).toUpperCase() +
                                            key.slice(1)}
                                        </th>
                                      )
                                    )}
                                  <th>Rx Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((item, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {Object.entries(item.data).map(([key, value], colIndex) => (
                                      <td key={colIndex}>
                                        {key === "rxData" ? (
                                          <ul>
                                            {Object.entries(JSON.parse(value)).map(([rxKey, rxValue], idx) => (
                                              <li key={idx}>
                                                <strong>{rxKey}:</strong> {rxValue}
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          value ? value.toString() : "-"
                                        )}
                                      </td>
                                    ))}

                                    <td>
                                      <span className="btn btn-primary btn-sm" onClick={() => openRxFormModel(rowIndex, item.data)}>Fill Rx</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />
                        <button
                          className="btn btn-primary me-2"
                          onClick={formPreview}
                          type="submit"
                        >
                          Preview
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => resetForm(e)}
                        >
                          Reset
                        </button>
                      </Form>
                    </>
                  ) : (
                    <FormPreview
                      formData={formPreviewValues}
                      onCancel={handleCancel}
                      onSubmit={handleSubmit}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <hr />

            {formDetails.length > 0 && (
              <Row>
                <Col lg={3} md={3} sm={3} className="mt-3"></Col>
                <Col lg={9} md={9} sm={9} className="mt-3 ">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>History</h5>
                    <div>
                      {formId == propertise.postHDformId ? (
                        <ReactToPrint
                          trigger={() => (
                            <button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print">
                              Print
                            </button>
                          )}
                          content={() => printRef.current}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div style={{ display: "none" }}>
                    <PrintPatientReport
                      ref={printRef}
                      patientData={patientData}
                      patientDataPre={patientDataPre}
                      patientDataDuring={patientDataDuring}
                      formDetails={formDetails}
                      formDetailsPre={formDetailsPre}
                      formDetailsDuring={formDetailsDuring}
                    />{" "}
                  </div>
                  {patientData.length > 0 ? (
                    <div className="treatmentTable">
                      <Table
                        hover
                        className="reportTables"
                        style={{ border: "1px solid" }}
                      >
                        <thead>
                          <tr>
                            <th>Datetime</th>
                            <th>Username</th>
                            <th>Action</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {patientData.map((rowData, i) => (
                            <tr>
                              <td>{rowData.datetime}</td>
                              <td>{rowData.staff}</td>
                              <td>
                                <select
                                  className="form-control"
                                  style={{ width: "auto" }}
                                  defaultValue={rowData.status}
                                  onChange={(e) =>
                                    handleUpdateStatus(e, rowData)
                                  }
                                >
                                  <option value={0}>-select-</option>
                                  <option value={4}>View</option>
                                  <option value={1}>Complete</option>

                                  {staffFormEditPermission.isEdit == 1 && (
                                    <>
                                      {formDetails[0].isTemplate == 1 && (
                                        <option value={5}>Template</option>
                                      )}

                                      {formDetails[0].isInvalidate == 1 && (
                                        <option value={2}>Invalidate</option>
                                      )}

                                      {formDetails[0].isAddendum == 1 && (
                                        <option value={3}>Addendum</option>
                                      )}

                                      {formDetails[0].isAcknowledgement ==
                                        1 && (
                                          <option value={6}>
                                            Review/Acknowledgement
                                          </option>
                                        )}
                                    </>
                                  )}
                                </select>
                              </td>
                              <td>
                                {rowData.status === 3 ? (
                                  <div className="statusAddendum">ADDENDUM</div>
                                ) : rowData.status === 2 ? (
                                  <div className="statusInvalid">
                                    INVALIDATED
                                  </div>
                                ) : rowData.status === 0 ? (
                                  <div className="statusInvalid">DRAFT</div>
                                ) : (
                                  <div className="statusActive">COMPLETED</div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p className="text-center">
                      <img src="assets/images/no-image.png" />
                    </p>
                  )}
                </Col>
              </Row>
            )}

            {showModal && (
              <AddendumModal
                showModal={showModal}
                handleClose={handleClose}
                handleSubmitInvalidAddendum={handleSubmitInvalidAddendum}
                currentRawData={currentRawData}
                status={status}
                formFields={formFields}
              />
            )}

            {isOpenRxFormModel && (
              <MedpasFormModel
                isOpenRxFormModel={isOpenRxFormModel}
                handleClose={handleClose}
                handleSubmitMedpasDetail={handleSubmitMedpasDetail}
              />
            )}
          </Container>
        </div>
      </div>

    </>
  );
};

export default Webform;
