import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import "../componentCss/sidebar.css";
import {
  getFormDataListAction,
  getFormDetailsAction,
  getPatientDetailsAction,
  getAllReportContentAction
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { decryptData } from './decrypt';
import config from "../../config/config";
import propertise from "../../config/properties";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const patientMrnNumber = localStorage.getItem('patientMrnNumber');

const today = new Date();
const options = { day: '2-digit', month: 'long', year: 'numeric' };
const todayDate = today.toLocaleDateString('en-GB', options);

const formId = propertise.medPassDrugFormId
const recordId = 0
const name = 'Prescription List'

const PrescriptionList = () => {
  const [formList, setFormData] = useState({});
  const [formDetails, setFormDetails] = useState([]);
  const [reason, setReason] = useState('');
  const [reasonStatus, setReasonStatus] = useState('');
  const [patientDetails, setPatientDetails] = useState([]);
  const allDataRef = useRef();
  const allDataRefRecords = useRef();
  const [reportData, setReportData] = useState('');

  const [staffFormEditPermission, setStaffFormEditPermission] = useState();

  useEffect(() => {
    getFormDetailsAPI();
    getReportContentList();

    if (patientMrnNumber) {
      getPatientDetailsAPI();
      getFormDataListAPI();
    }
  }, []);

  const getReportContentList = async () => {
    let res = await getAllReportContentAction();
    if (res.success) {
      let decryptedData = decryptData(res.data);
      setReportData(decryptedData)
    }
  };

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  const printAllDataRecords = useReactToPrint({
    content: () => allDataRefRecords.current,
  });


  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
      setStaffFormEditPermission(decryptData(res.staffFormEditPermission));
    }
  };

  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({ id: formId, recordId: recordId, mrnNumber: patientMrnNumber });
    if (res.success) {
      let data = decryptData(res.data?.records);
      localStorage.setItem('mrnNumber', data[0].mrnNumber);
      setFormData(data[0]);
      setReason(decryptData(res.data.reasonOfRecord));
      setReasonStatus(decryptData(res.data.reasonStatus));
    }
  };

  let getPatientDetailsAPI = async () => {
    let res = await getPatientDetailsAction({ mrnNumber: patientMrnNumber });
    if (res.success) {
      let data = decryptData(res.data);
      const extractedValues = data.filter(item => item.formDetailsId === 16 || item.formDetailsId === 18).map(item => item.fieldValue);
      setPatientDetails(extractedValues);
    }
  };


  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        {/* <Sidebar /> */}
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={3} md={3} sm={3}> <div className="form-preview-container">
                <AssignedFormSidebar />
              </div></Col>
              <Col lg={9} md={9} sm={9}> <div className="form-preview-container" id="example-collapse-text">
                <h2>{name} Record Details</h2>
                <Link to={formId == 3 || formId == 4 || formId == 5 ? `${config.baseUrl}staffDashboard` : `${config.baseUrl}viewTreatment/${formId}/${recordId}/${name}`} >
                  <span style={{ float: 'right', marginTop: '-15px' }}>Back</span>
                </Link>
                <ul className="list-group">
                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>Patient Name:</strong></div>
                    {patientDetails[0] ? patientDetails[0] : ''} {patientDetails[1] ? patientDetails[1] : ''}
                  </li>

                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>MRN Number:</strong></div>
                    {formList.mrnNumber}
                  </li>

                  <li className="list-group-item d-flex flex-row ">
                    <div className="w-50"><strong>Emirates ID Number:</strong></div>
                    {formList.emrNumber}
                  </li>

                  {formDetails.map((fields, index) => (
                    <li key={index} className="list-group-item d-flex flex-row ">
                      <div>
                        <table className="table table-responsive" style={{ width: '100%' }}>
                          <thead>
                            <tr>
                              {formList[fields?.id] && JSON.parse(formList[fields?.id]).length > 0 ? (
                                Object.keys(JSON.parse(formList[fields?.id])[0]).map((key, index) => (
                                  <th key={index} style={{ textAlign: 'left' }}>{key.replace(/([A-Z])/g, ' $1')}</th>
                                ))
                              ) : (
                                <th>No Data Available</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {formList[fields?.id] ? (
                              JSON.parse(formList[fields?.id]).map((item, index) => (
                                <tr key={index}>
                                  {Object.entries(item).map(([key, value]) => (
                                    <td key={key}>
                                      {typeof value === 'object' && value !== null ? (
                                        Object.entries(value).map(([subKey, subValue]) => (
                                          <div key={subKey}><strong>{subKey}:</strong> {subValue}</div>
                                        ))
                                      ) : (
                                        value !== null && value !== undefined ? value.toString() : "-"
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="100%" style={{ textAlign: 'center' }}>No Data Available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  ))}
                </ul>
              </div></Col>
            </Row>
          </Container>
        </div>
      </div >
    </>
  );
};
export default PrescriptionList;